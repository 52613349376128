//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import axios from "axios"
import { retrieveButter,
         getStateRules,
         getElections,
         getUpcomingElections,
         getDictionary,
         getDictFLJ,
         getDictWP,
         getLang,
         getChosenState,
         getNewVfaDate,
         updateFvapLeosWithButter } from '~/utils/butterUtils'
import { DEFAULT_STATE_VOTING_RULE,
         getVFAParameters } from '~/utils/VFAParameters'
import snarkdown from 'snarkdown'

export default {
  head () {
    return {
      title: this.pageTitle,
      meta: [
        {
          hid: 'description',
          name: 'description',
          content: this.pageMetaDescription
        }
      ],
      link: [ 
        {
          rel: 'canonical',
          href: process.env.url+this.$route.path
        }
      ]
    }
  },
  async asyncData ({ store, params }) {
    await retrieveButter(store, 'pages/states/_state.vue asyncData ')

    let fvapLeos = (await axios.get(`/leos/${params.state.toUpperCase()}-leos.json`)).data
    return {
      fvapLeos: fvapLeos.filter(leo => leo.fpcaOffice)
    }
  },
  data () {
    return {
      typedLeo: "",
      currentLeo: '',
      fvapLeos: [],
      jurisdiction: '',
      isLoading: true,
      nGoBack: -2, /** browser history, go back two "screens" */
    }
  },
  computed: {
    showTestData () {
      return this.$store.state.showTestData
    },
    showCodeFragmentMark () {
      return this.$store.state.showCodeFragmentMark
    },
    lang () {
      return getLang(this.$i18n.locale)
    },
    pageMetaDescription () {
      return getDictWP(this.dict.SEO_A26, { state: getDictFLJ( `states.${this.stateRules.iso}`, this.dict) })
    },
    pageTitle () {
      let vr = this.dict.E00.replace("State ", "") // remove "State" so territories are not mislabelled
      return process.env.siteTitle + " | " +  this.getDictFLJ(`states.${this.stateRules.iso}`, this.dict)
    },
    dateFormat () {
      return this.$i18n.locales.filter(item => item.code === this.$i18n.locale)[0].iso
    },
    selectedLeo () {
      return this.$store.state.leo.leo ? this.$store.state.leo.leo : {}
    },
    chosenState () {
      return getChosenState(this.$store, this.$route)
    },
    OverseasStatusInfo () {
      let tip = this.dict.M43
          + this.stateRules.overseas_status_uncertain_return+(this.stateRules.overseas_status_uncertain_return ? "<br><br>" : "")
          + this.stateRules.overseas_status_intend_to_return+(this.stateRules.overseas_status_intend_to_return ? "<br><br>" : "")
          + this.stateRules.overseas_status_never_resided_in_us+(this.stateRules.overseas_status_never_resided_in_us ? "<br><br>" : "")
          + this.stateRules.overseas_status_military_active_duty+(this.stateRules.overseas_status_military_active_duty ? "<br><br>" : "")
          + this.stateRules.overseas_status_military_spouse+(this.stateRules.overseas_status_military_spouse ? "<br><br>" : "")
      tip = tip.replace(/\\n/gi, '<br>').replace(/\\/gi, '')
      return getDictWP(tip, {"state": getDictFLJ(`states.${this.chosenState}`, this.dict)})
    },
    stateRules () {
      let scr0 = this.butterStateVotingRules
      if (scr0) {
        let scr1 = scr0.find(x => x.stateid.toLowerCase().slice(0, 2) === this.$route.params.state.toLowerCase())
        return getStateRules(scr1, this.lang, this.$store.state.showDictionaryKeys)
      } else {
        return DEFAULT_STATE_VOTING_RULE
      }
    },
    elections () {
      const scr0 = this.butterStateElections
      if (scr0) {
        const scr1 = scr0.filter(item => item.stateid.toLowerCase().slice(0, 2) === this.$route.params.state.toLowerCase())
        return getElections(scr1, this.FWAB_TRIGGER_DEFAULT, this.lang)
      } else {
        return []
      }
    },
    upcomingElections () {
      return getUpcomingElections (this.elections)
    },
    stateLeos () {
      const b0 = this.butterLeos
      let b1 = (b0) ? b0.filter(leo => leo.s_state.slice(0,2) === this.$route.params.state.toLowerCase()) : []
      let ul = updateFvapLeosWithButter(this.fvapLeos, b1)
      return ul.filter(leo => leo.fpcaOffice)
    },
    VFAParameters () {
      return getVFAParameters(this.$store)
    },
    FWAB_TRIGGER_DEFAULT () {
          return this.VFAParameters.FWAB_TRIGGER_DEFAULT
    },
    dict () {
      if (this.butterDictionary) {
        return getDictionary(this.butterDictionary, this.lang, 'd', this.$store.state.showDictionaryKeys)
      } else {
        return { "ERROR": "error"}
      }
    },
    dictwys () {
      if (this.butterDictionaryWYSIWYG) {
        return getDictionary(this.butterDictionaryWYSIWYG, this.lang, 'w', this.$store.state.showDictionaryKeys)
      } else {
        return { "ERROR" : "Could not retrieve dictionary."}
      }
    },
    butterLeos () {
      return this.$store.state.butterLeos
    },
    butterStateElections () {
      return this.$store.state.butterStateElections
    },
    butterStateVotingRules () {
      return this.$store.state.butterStateVotingRules
    },
    butterDictionary () {
      return this.$store.state.butterDictionary
    },
    butterDictionaryWYSIWYG () {
      return this.$store.state.butterDictionaryWYSIWYG
    },
  },
  mounted () {
    this.isLoading = false

    if (this.stateRules.issues1) {
      this.$nextTick(()=>{
        if (this.$route.query && this.$route.query.specialIssues==='true') {
          this.$refs.specialIssues.scrollIntoView()
        }
      })
    }
  },
  methods: {
    localizeIfAvailable (str) {
      if (typeof str !== 'string') {
        return str
      }
      let newStr = str
      if (str.includes('*')) {
        newStr = str.replace(/\*/g, '')
      }

      let t  = this.getDictFLJ(`election.${newStr.toLowerCase().replace(/\s/gi, '')}`, this.dict)

      return t!=="" ? t : newStr
    },
    camelize (str) {
      return str.replace(/(?:^\w|[A-Z]|\b\w|\s+)/g, function (match, index) {
        if (+match === 0) return '' // or if (/\s+/.test(match)) for white spaces
        return index === 0 ? match.toLowerCase() : match.toUpperCase()
      })
    },
    md (md) {
      return snarkdown(md)
    },
    getNewVfaDate() {
      return getNewVfaDate()
    },
    getDictFLJ(dictItem, dict) {
      /**
       * 2022-12-04 John Yee
       * This construction looks weird - like a recursive call; but, it's not.
       * The "getDictFLJ(dictItem, dict)" in the return statement is really
       * the function getDictFLJ(dictItem, dict) that is imported from ~/utils/butterUtils.js
       * 
       * reference: https://stackoverflow.com/questions/52332993/calling-a-function-from-a-helper-in-vue-template
       */
      return getDictFLJ(dictItem, dict)
    },
    getDictWP(dictItem, paramObj) {
      /**
       * 2022-12-04 John Yee
       * This construction looks weird - like a recursive call; but, it's not.
       * The "getDictWP(dictItem, paramObj)" in the return statement is really
       * the function getDictWP that is imported from ~/utils/butterUtils.js
       * 
       * reference: https://stackoverflow.com/questions/52332993/calling-a-function-from-a-helper-in-vue-template
       */
      return getDictWP(dictItem, paramObj)
    },
  }
}
